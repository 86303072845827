import React from "react"
import Faq from "../components/Home/FAQ/Faq"
import Footer from "../components/Home/Footer/Footer"
import Header from "../components/Home/Header/Header"
import Technology from "../components/Home/Technology/Technology"
import Features from "../components/Home/Features/Features"
import "../styles/global.css"
import Services from "../components/Home/Services/Services"
import TechnologyStack from "../components/Home/TechnologyStack/TechnologyStack"
import Timeline from "../components/Home/Timeline/Timeline"

export default function Home() {
    return (
        <>  
            <Header showLogin={true} />
            <Services />
            <Technology />
            <TechnologyStack />
            <Timeline />
            <Features />
            <Faq />
            <Footer />
        </>
    )
}
